import anime from 'animejs';

buildMenu();

function buildMenu(){
  const navButton = document.querySelector('.hamburger-box');
  // const navButton = document.querySelector('.hamburger');
  const navMenu = document.querySelector('.nav-menu');
  const bgStagger = navMenu.querySelectorAll('.bg-staggered');

  let isOpen = false;
  let anim = anime.timeline({autoplay: false})
             .add({
                targets: '.nav-menu__content',
                opacity: [1, 1],

                easing: 'linear',
                duration: 200
              })
             .add({
                targets: '.main',
                translateX: ['10','0%'],

                easing: 'easeInOutQuart',
                duration: 700,
                offset: 0
              })
             .add({
               targets: bgStagger,
               scaleX: [1, 0],

              //  direction: 'reverse',
               easing: 'easeInOutQuart',
               duration: 700,
               offset: 0,

               delay: ( el, index, total ) =>{
                 return (total-index)*100;
               }
             })
             .add({
               targets: '.nav-menu__links li, .nav-menu__footer',
               translateX: ['0', '-30%'],
               opacity: [1, 0],

               easing: 'easeInOutQuart',
               duration: 400,
               offset: 0,

               delay: ( el, index, total ) =>{
                 return ( total - index )*30;
               }
             })
             .add({
               targets: '.bg-flower',
               translateY: [' 0,', '-20%'],
               opacity: ['1', '0'],
               rotate: ['0', '45deg'],

               easing: 'easeInQuart',
               duration: 700,
               offset: 0
             });

  anime({
     targets: '.nav-menu__content',
     opacity: 0,

     easing: 'linear',
     duration: 0
   });

  window.addEventListener('keyup', e=>{
    if( e.keyCode == 27 || e.key == 'Escape' ){
      if( isOpen ) toggleNav( e );
    }
  });

  navButton.addEventListener('click', toggleNav);

  function toggleNav(){
    isOpen = !isOpen;

    navMenu.classList.toggle('is-open');
    navButton.classList.toggle('is-open');

    anim.play();
    anim.reverse();
  }

  linkJacker();
  function linkJacker(){
    let animDuration = 1000;
    // filter out links that arnt redirects
    let links = Array.from( document.querySelectorAll('a') );
    let navLinks = links.filter( el =>{
      return el.getAttribute('target') != '_blank';
    });

    navLinks.forEach( a =>{
      a.addEventListener('click', e =>{
        e.preventDefault();

        if( a.href == window.location ) return;

        // execute animation here
        if( isOpen ) toggleNav(e);
        animateExit( animDuration );
        // ------------------------

        setTimeout( () => window.location = a.href, animDuration );
      });
    });

  }

  function animateExit( _duration ){
    anime.timeline()
      .add({
        targets: '.main',
        translateX: ['0%', '5'],

        duration: _duration,
        easing: 'easeOutQuad'
      })
      .add({
          targets: '.loader',
          opacity: ['0', '1'],

          duration: _duration,
          offset: 200,
          easing: 'easeOutQuad'
      });
  }

  window.addEventListener('load', () =>{
    anime.timeline()
      .add({
        targets: '.main',
        translateX: ['5%', '0'],
        opacity: ['0', '1'],

        duration: 600,
        easing: 'easeOutQuad'
      })
      .add({
          targets: '.loader',
          opacity: ['1', '0'],

          duration: 600,
          offset: 200,
          easing: 'easeOutQuad'
      });
  });
}
