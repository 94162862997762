import VanillaModal from 'vanilla-modal'
import axios from 'axios'

import { pageCheck, mobileCheck } from './../utils.js'

let modal

// build modal
buildModal()

modal = new VanillaModal()

if (pageCheck('home')) popupModal()
if (pageCheck('gallery')) galleryModal()
// if ((0, _utils.pageCheck)('gallery')) galleryModalVideo()
if (pageCheck('gallery')) galleryModalVideo()
if (pageCheck('plans')) plansModal()

function buildModal() {
  // create document fragment
  let vanilla_modal = document.createDocumentFragment()

  // create vanilla modal
  let modal_outer = document.createElement('div')
  modal_outer.setAttribute('class', 'modal')

  // create inner
  let modal_inner = document.createElement('div')
  modal_inner.setAttribute('class', 'modal-inner')

  // create close button
  let modal_close = document.createElement('a')
  modal_close.innerHTML = 'X'
  modal_close.setAttribute('data-modal-close', '')

  // create content
  let modal_content = document.createElement('div')
  modal_content.setAttribute('class', 'modal-content')

  // create content container
  let modal_container = document.createElement('div')
  modal_container.setAttribute('id', 'modal-container')
  modal_container.setAttribute('style', 'display: none;')

  // append childs
  modal_inner.appendChild(modal_close)
  modal_inner.appendChild(modal_content)
  modal_outer.appendChild(modal_inner)
  vanilla_modal.appendChild(modal_outer)

  // append vanilla modal to document
  document.body.appendChild(modal_container)
  document.body.appendChild(vanilla_modal)

  bindEvents()

  function bindEvents() {
    document.querySelectorAll('[data-modal]').forEach(el => {
      const content = el.getAttribute('data-content')
      el.addEventListener('click', () => {
        axios
          .get(`/${content}`)
          .then(response => {
            let data = response.data
            let modalC = document.querySelector('#modal-container')
            let modalOpenC = document.querySelector('.modal-content')

            modalC.innerHTML = data
            modalOpenC.innerHTML = ''
            modal.open('#modal-container')
          })
          .catch(() => {
            return
          })
      })
    })
  }
}

function galleryModal() {
  const imageModal = document.querySelectorAll('[data-modal-image]')

  window.addEventListener('resize', bindEvents)

  bindEvents()
  function bindEvents() {
    // bind unbind click event
    if (mobileCheck()) imageModal.forEach(el => el.addEventListener('click', openModal))
    else imageModal.forEach(el => el.removeEventListener('click', openModal))
  }

  function openModal() {
    var content = this.getAttribute('data-modal-image')

    var img = new Image()
    img.src = content

    var modalC = document.querySelector('#modal-container')
    var modalOpenC = document.querySelector('.modal-content')

    modalC.innerHTML = ''
    modalOpenC.innerHTML = ''
    modalC.appendChild(img)
    modal.open('#modal-container')
  }
}

function openModalVideo() {
  var videoContent = this.getAttribute('data-modal-video')

  var ifrm = document.createElement('iframe')
  ifrm.setAttribute('src', 'https://www.youtube.com/embed/UePYT2Rccg8')
  ifrm.style.width = '100%'
  ifrm.style.height = '100%'
  // iframe.src = videoContent;

  var modalC = document.querySelector('#modal-container')
  var modalOpenC = document.querySelector('.modal-content')

  modalC.innerHTML = ''
  modalOpenC.innerHTML = ''
  modalC.appendChild(ifrm)
  modal.open('#modal-container')
}
// }

function plansModal() {
  const specsButton = document.querySelectorAll('.specs-opener')

  specsButton.forEach(el => {
    const type = el.getAttribute('data-type')
    el.addEventListener('click', () => getSpecs(type))
  })

  function getSpecs(type) {
    axios.get('/products/specifications/', { responseType: 'document' }).then(response => {
      const data = response.data
      const specs = data.querySelector(`.${type}`)

      let modalC = document.querySelector('#modal-container')
      let modalOpenC = document.querySelector('.modal-content')

      modalC.appendChild(specs)
      modalOpenC.innerHTML = ''
      modal.open('#modal-container')
    })
  }
}

function popupModal() {
  window.addEventListener('load', () => {
    setTimeout(() => {
      modal.open('.popup-modal')
    }, 1000)
  })
}
