import { pageCheck, orientationCheck } from './../utils.js'

if (typeof $ !== 'undefined') {
  $(function() {
    // ============================================
    // Concept Page Slider
    // ============================================
    if (pageCheck('concept')) buildConceptSlider()

    function buildConceptSlider() {
      // initialize left slider
      $('.concept-slider__content').slick({
        asNavFor: '.concept-slider__visuals',
        arrows: true,
        appendArrows: $('.slider__nav'),
        cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
        speed: 650,

        prevArrow: '<button type="button" class="slick-prev"><span><</span>PREV</button>',
        nextArrow: '<button type="button" class="slick-next">NEXT<span>></span></button>',
      })

      $('.concept-slider__content').on('beforeChange', (e, s, cs, ns) =>
        readCurrentSlide('.slider__nav p', ns + 1)
      )

      // initialize right slider
      $('.concept-slider__visuals').slick({
        asNavFor: '.concept-slider__content',
        arrows: false,
        cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
        speed: 650,

        focusOnSelect: false,
        accesibility: false,
        draggable: false,
        touchMove: false,
        swipe: false,
      })

      // run once to get value
      readCurrentSlide('.slider__nav p')

      function readCurrentSlide(appendTo, currentSlide) {
        let parent = document.querySelector(appendTo)
        let currentLog = parent.querySelectorAll('span')[0]
        let totalLog = parent.querySelectorAll('span')[2]

        let currentVal = currentSlide || $('.concept-slider__content').slick('slickCurrentSlide') + 1

        currentLog.innerHTML = `0${currentVal}`
        totalLog.innerHTML = `0${$('.concept-slider__content').slick('getSlick').$slides.length}`
      }
    }

    // ============================================
    // Gallery Page Slider
    // ============================================

    if (pageCheck('gallery')) toggleGallerySlider()

    function toggleGallerySlider() {
      let initialized = false

      const orientation = orientationCheck()
      const $slider = $('.gallery__slider')

      if (orientation == 'landscape' && !initialized) build()
      else if (orientation == 'portrait') destroy()

      window.addEventListener('resize', () => {
        if (orientationCheck() == 'landscape' && !initialized) build()
        else if (orientationCheck() == 'portrait') destroy()
      })

      function build() {
        if ($slider.hasClass('no-slider')) {
          $(document.body).removeClass('no-slider')
          $slider.removeClass('no-slider')
        }

        $slider.on('init', () => {
          initialized = true
        })

        $slider.slick({
          autoplay: true,
          autoplaySpeed: 2000,
          arrows: false,
          appendArrows: $('.slider__nav'),
          cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
          speed: 2000,
          dots: true,

          prevArrow: '<button type="button" class="slick-prev"><span><</span>PREV</button>',
          nextArrow: '<button type="button" class="slick-next">NEXT<span>></span></button>',
        })
      }

      function destroy() {
        $(document.body).addClass('no-slider')
        $slider.addClass('no-slider')

        if (initialized) {
          $slider.slick('unslick')
          initialized = false
        }
      }
    }

    // ============================================
    // Plans Page Slider
    // ============================================
    if (pageCheck('plans')) buildPlansSlider()

    function buildPlansSlider() {
      const $slider = $('.plans__slider')
      $slider.slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        appendArrows: $('.slider__nav'),
        cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
        speed: 650,
        swipe: false,
        touchMove: false,
        swipeToSlide: false,
        dots: true,

        prevArrow: '<button type="button" class="slick-prev"><span><</span>PREV</button>',
        nextArrow: '<button type="button" class="slick-next">NEXT<span>></span></button>',
      })
    }
  })
}
