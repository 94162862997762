import './polyfills/forEach'
import 'popper.js'
import 'bootstrap'

// import modules
import './modules/nav-menu.js'

// import library setup
import './lib-settings/_slick.js'
import './lib-settings/_drift-zoom.js'
import './lib-settings/_vanilla-modal.js'
