// import Drift from 'drift-zoom/dist/Drift.min.js';
import { pageCheck, mobileCheck } from './../utils.js'
var util = require('util')

// drift 0.4.0
!(function(e) {
  if ('object' == typeof exports && 'undefined' != typeof module) module.exports = e()
  else if ('function' == typeof define && define.amd) define([], e)
  else {
    var t
    ;(t =
      'undefined' != typeof window
        ? window
        : 'undefined' != typeof global
        ? global
        : 'undefined' != typeof self
        ? self
        : this),
      (t.Drift = e())
  }
})(function() {
  return (function e(t, n, i) {
    function s(l, a) {
      if (!n[l]) {
        if (!t[l]) {
          var r = 'function' == typeof require && require
          if (!a && r) return r(l, !0)
          if (o) return o(l, !0)
          var h = new Error("Cannot find module '" + l + "'")
          throw ((h.code = 'MODULE_NOT_FOUND'), h)
        }
        var d = (n[l] = { exports: {} })
        t[l][0].call(
          d.exports,
          function(e) {
            var n = t[l][1][e]
            return s(n ? n : e)
          },
          d,
          d.exports,
          e,
          t,
          n,
          i
        )
      }
      return n[l].exports
    }
    for (var o = 'function' == typeof require && require, l = 0; l < i.length; l++) s(i[l])
    return s
  })(
    {
      1: [
        function(e, t, n) {
          'use strict'
          function i(e) {
            return e && e.__esModule ? e : { default: e }
          }
          function s(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function')
          }
          Object.defineProperty(n, '__esModule', { value: !0 })
          var o = (function() {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var i = t[n]
                  ;(i.enumerable = i.enumerable || !1),
                    (i.configurable = !0),
                    'value' in i && (i.writable = !0),
                    Object.defineProperty(e, i.key, i)
                }
              }
              return function(t, n, i) {
                return n && e(t.prototype, n), i && e(t, i), t
              }
            })(),
            l = e('./util/throwIfMissing'),
            a = i(l),
            r = e('./util/dom'),
            h = (function() {
              function e(t) {
                s(this, e), (this.isShowing = !1)
                var n = t.namespace,
                  i = void 0 === n ? null : n,
                  o = t.zoomFactor,
                  l = void 0 === o ? (0, a['default'])() : o,
                  r = t.containerEl,
                  h = void 0 === r ? (0, a['default'])() : r
                ;(this.settings = { namespace: i, zoomFactor: l, containerEl: h }),
                  (this.openClasses = this._buildClasses('open')),
                  this._buildElement()
              }
              return (
                o(e, [
                  {
                    key: '_buildClasses',
                    value: function(e) {
                      var t = ['drift-' + e],
                        n = this.settings.namespace
                      return n && t.push(n + '-' + e), t
                    },
                  },
                  {
                    key: '_buildElement',
                    value: function() {
                      ;(this.el = document.createElement('div')),
                        (0, r.addClasses)(this.el, this._buildClasses('bounding-box'))
                    },
                  },
                  {
                    key: 'show',
                    value: function(e, t) {
                      ;(this.isShowing = !0), this.settings.containerEl.appendChild(this.el)
                      var n = this.el.style
                      ;(n.width = Math.round(e / this.settings.zoomFactor) + 'px'),
                        (n.height = Math.round(t / this.settings.zoomFactor) + 'px'),
                        (0, r.addClasses)(this.el, this.openClasses)
                    },
                  },
                  {
                    key: 'hide',
                    value: function() {
                      this.isShowing && this.settings.containerEl.removeChild(this.el),
                        (this.isShowing = !1),
                        (0, r.removeClasses)(this.el, this.openClasses)
                    },
                  },
                  {
                    key: 'setPosition',
                    value: function(e, t, n) {
                      var i = window.pageXOffset,
                        s = window.pageYOffset,
                        o = n.left + e * n.width - this.el.clientWidth / 2 + i,
                        l = n.top + t * n.height - this.el.clientHeight / 2 + s
                      this.el.getBoundingClientRect()
                      o < n.left + i
                        ? (o = n.left + i)
                        : o + this.el.clientWidth > n.left + n.width + i &&
                          (o = n.left + n.width - this.el.clientWidth + i),
                        l < n.top + s
                          ? (l = n.top + s)
                          : l + this.el.clientHeight > n.top + n.height + s &&
                            (l = n.top + n.height - this.el.clientHeight + s),
                        (this.el.style.left = o + 'px'),
                        (this.el.style.top = l + 'px')
                    },
                  },
                ]),
                e
              )
            })()
          n['default'] = h
        },
        { './util/dom': 6, './util/throwIfMissing': 7 },
      ],
      2: [
        function(e, t, n) {
          'use strict'
          function i(e) {
            return e && e.__esModule ? e : { default: e }
          }
          function s(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function')
          }
          var o = (function() {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var i = t[n]
                  ;(i.enumerable = i.enumerable || !1),
                    (i.configurable = !0),
                    'value' in i && (i.writable = !0),
                    Object.defineProperty(e, i.key, i)
                }
              }
              return function(t, n, i) {
                return n && e(t.prototype, n), i && e(t, i), t
              }
            })(),
            l = e('./util/dom'),
            a = e('./injectBaseStylesheet'),
            r = i(a),
            h = e('./Trigger'),
            d = i(h),
            u = e('./ZoomPane'),
            c = i(u)
          t.exports = (function() {
            function e(t) {
              var n = this,
                i = arguments.length <= 1 || void 0 === arguments[1] ? {} : arguments[1]
              if (
                (s(this, e),
                (this.VERSION = '0.4.0'),
                (this.destroy = function() {
                  n.trigger._unbindEvents()
                }),
                (this.triggerEl = t),
                !(0, l.isDOMElement)(this.triggerEl))
              )
                throw new TypeError('`new Drift` requires a DOM element as its first argument.')
              var o = i.namespace,
                a = void 0 === o ? null : o,
                h = i.showWhitespaceAtEdges,
                d = void 0 !== h && h,
                u = i.containInline,
                c = void 0 !== u && u,
                f = i.inlineOffsetX,
                m = void 0 === f ? 0 : f,
                g = i.inlineOffsetY,
                v = void 0 === g ? 0 : g,
                p = i.sourceAttribute,
                y = void 0 === p ? 'data-zoom' : p,
                w = i.zoomFactor,
                b = void 0 === w ? 3 : w,
                _ = i.paneContainer,
                E = void 0 === _ ? document.body : _,
                C = i.inlinePane,
                B = void 0 === C ? 375 : C,
                x = i.handleTouch,
                z = void 0 === x || x,
                k = i.onShow,
                L = void 0 === k ? null : k,
                M = i.onHide,
                S = void 0 === M ? null : M,
                P = i.injectBaseStyles,
                O = void 0 === P || P,
                T = i.hoverDelay,
                H = void 0 === T ? 0 : T,
                D = i.touchDelay,
                A = void 0 === D ? 0 : D,
                I = i.hoverBoundingBox,
                F = void 0 !== I && I,
                W = i.touchBoundingBox,
                j = void 0 !== W && W
              if (B !== !0 && !(0, l.isDOMElement)(E))
                throw new TypeError('`paneContainer` must be a DOM element when `inlinePane !== true`')
              ;(this.settings = {
                namespace: a,
                showWhitespaceAtEdges: d,
                containInline: c,
                inlineOffsetX: m,
                inlineOffsetY: v,
                sourceAttribute: y,
                zoomFactor: b,
                paneContainer: E,
                inlinePane: B,
                handleTouch: z,
                onShow: L,
                onHide: S,
                injectBaseStyles: O,
                hoverDelay: H,
                touchDelay: A,
                hoverBoundingBox: F,
                touchBoundingBox: j,
              }),
                this.settings.injectBaseStyles && (0, r['default'])(),
                this._buildZoomPane(),
                this._buildTrigger()
            }
            return (
              o(e, [
                {
                  key: '_buildZoomPane',
                  value: function() {
                    this.zoomPane = new c['default']({
                      container: this.settings.paneContainer,
                      zoomFactor: this.settings.zoomFactor,
                      showWhitespaceAtEdges: this.settings.showWhitespaceAtEdges,
                      containInline: this.settings.containInline,
                      inline: this.settings.inlinePane,
                      namespace: this.settings.namespace,
                      inlineOffsetX: this.settings.inlineOffsetX,
                      inlineOffsetY: this.settings.inlineOffsetY,
                    })
                  },
                },
                {
                  key: '_buildTrigger',
                  value: function() {
                    this.trigger = new d['default']({
                      el: this.triggerEl,
                      zoomPane: this.zoomPane,
                      handleTouch: this.settings.handleTouch,
                      onShow: this.settings.onShow,
                      onHide: this.settings.onHide,
                      sourceAttribute: this.settings.sourceAttribute,
                      hoverDelay: this.settings.hoverDelay,
                      touchDelay: this.settings.touchDelay,
                      hoverBoundingBox: this.settings.hoverBoundingBox,
                      touchBoundingBox: this.settings.touchBoundingBox,
                      namespace: this.settings.namespace,
                      zoomFactor: this.settings.zoomFactor,
                    })
                  },
                },
                {
                  key: 'setZoomImageURL',
                  value: function(e) {
                    this.zoomPane._setImageURL(e)
                  },
                },
                {
                  key: 'disable',
                  value: function() {
                    this.trigger.enabled = !1
                  },
                },
                {
                  key: 'enable',
                  value: function() {
                    this.trigger.enabled = !0
                  },
                },
                {
                  key: 'isShowing',
                  get: function() {
                    return this.zoomPane.isShowing
                  },
                },
                {
                  key: 'zoomFactor',
                  get: function() {
                    return this.settings.zoomFactor
                  },
                  set: function(e) {
                    ;(this.settings.zoomFactor = e), (this.zoomPane.settings.zoomFactor = e)
                  },
                },
              ]),
              e
            )
          })()
        },
        { './Trigger': 3, './ZoomPane': 4, './injectBaseStylesheet': 5, './util/dom': 6 },
      ],
      3: [
        function(e, t, n) {
          'use strict'
          function i(e) {
            return e && e.__esModule ? e : { default: e }
          }
          function s(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function')
          }
          Object.defineProperty(n, '__esModule', { value: !0 })
          var o = (function() {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var i = t[n]
                  ;(i.enumerable = i.enumerable || !1),
                    (i.configurable = !0),
                    'value' in i && (i.writable = !0),
                    Object.defineProperty(e, i.key, i)
                }
              }
              return function(t, n, i) {
                return n && e(t.prototype, n), i && e(t, i), t
              }
            })(),
            l = e('./util/throwIfMissing'),
            a = i(l),
            r = e('./BoundingBox'),
            h = i(r),
            d = (function() {
              function e() {
                var t = arguments.length <= 0 || void 0 === arguments[0] ? {} : arguments[0]
                s(this, e), u.call(this)
                var n = t.el,
                  i = void 0 === n ? (0, a['default'])() : n,
                  o = t.zoomPane,
                  l = void 0 === o ? (0, a['default'])() : o,
                  r = t.sourceAttribute,
                  d = void 0 === r ? (0, a['default'])() : r,
                  c = t.handleTouch,
                  f = void 0 === c ? (0, a['default'])() : c,
                  m = t.onShow,
                  g = void 0 === m ? null : m,
                  v = t.onHide,
                  p = void 0 === v ? null : v,
                  y = t.hoverDelay,
                  w = void 0 === y ? 0 : y,
                  b = t.touchDelay,
                  _ = void 0 === b ? 0 : b,
                  E = t.hoverBoundingBox,
                  C = void 0 === E ? (0, a['default'])() : E,
                  B = t.touchBoundingBox,
                  x = void 0 === B ? (0, a['default'])() : B,
                  z = t.namespace,
                  k = void 0 === z ? null : z,
                  L = t.zoomFactor,
                  M = void 0 === L ? (0, a['default'])() : L
                ;(this.settings = {
                  el: i,
                  zoomPane: l,
                  sourceAttribute: d,
                  handleTouch: f,
                  onShow: g,
                  onHide: p,
                  hoverDelay: w,
                  touchDelay: _,
                  hoverBoundingBox: C,
                  touchBoundingBox: x,
                  namespace: k,
                  zoomFactor: M,
                }),
                  (this.settings.hoverBoundingBox || this.settings.touchBoundingBox) &&
                    (this.boundingBox = new h['default']({
                      namespace: this.settings.namespace,
                      zoomFactor: this.settings.zoomFactor,
                      containerEl: this.settings.el.offsetParent,
                    })),
                  (this.enabled = !0),
                  this._bindEvents()
              }
              return (
                o(e, [
                  {
                    key: '_bindEvents',
                    value: function() {
                      this.settings.el.addEventListener('mouseenter', this._handleEntry, !1),
                        this.settings.el.addEventListener('mouseleave', this._hide, !1),
                        this.settings.el.addEventListener('mousemove', this._handleMovement, !1),
                        this.settings.handleTouch &&
                          (this.settings.el.addEventListener('touchstart', this._handleEntry, !1),
                          this.settings.el.addEventListener('touchend', this._hide, !1),
                          this.settings.el.addEventListener('touchmove', this._handleMovement, !1))
                    },
                  },
                  {
                    key: '_unbindEvents',
                    value: function() {
                      this.settings.el.removeEventListener('mouseenter', this._handleEntry, !1),
                        this.settings.el.removeEventListener('mouseleave', this._hide, !1),
                        this.settings.el.removeEventListener('mousemove', this._handleMovement, !1),
                        this.settings.handleTouch &&
                          (this.settings.el.removeEventListener('touchstart', this._handleEntry, !1),
                          this.settings.el.removeEventListener('touchend', this._hide, !1),
                          this.settings.el.removeEventListener('touchmove', this._handleMovement, !1))
                    },
                  },
                  {
                    key: 'isShowing',
                    get: function() {
                      return this.settings.zoomPane.isShowing
                    },
                  },
                ]),
                e
              )
            })(),
            u = function() {
              var e = this
              ;(this._handleEntry = function(t) {
                t.preventDefault(),
                  (e._lastMovement = t),
                  'mouseenter' == t.type && e.settings.hoverDelay
                    ? (e.entryTimeout = setTimeout(e._show, e.settings.hoverDelay))
                    : e.settings.touchDelay
                    ? (e.entryTimeout = setTimeout(e._show, e.settings.touchDelay))
                    : e._show()
              }),
                (this._show = function() {
                  if (e.enabled) {
                    var t = e.settings.onShow
                    if (
                      (t && 'function' == typeof t && t(),
                      e.settings.zoomPane.show(
                        e.settings.el.getAttribute(e.settings.sourceAttribute),
                        e.settings.el.clientWidth,
                        e.settings.el.clientHeight
                      ),
                      e._lastMovement)
                    ) {
                      var n = e._lastMovement.touches
                      ;((n && e.settings.touchBoundingBox) || (!n && e.settings.hoverBoundingBox)) &&
                        e.boundingBox.show(
                          e.settings.zoomPane.el.clientWidth,
                          e.settings.zoomPane.el.clientHeight
                        )
                    }
                    e._handleMovement()
                  }
                }),
                (this._hide = function(t) {
                  t.preventDefault(),
                    (e._lastMovement = null),
                    e.entryTimeout && clearTimeout(e.entryTimeout),
                    e.boundingBox && e.boundingBox.hide()
                  var n = e.settings.onHide
                  n && 'function' == typeof n && n(), e.settings.zoomPane.hide()
                }),
                (this._handleMovement = function(t) {
                  if (t) t.preventDefault(), (e._lastMovement = t)
                  else {
                    if (!e._lastMovement) return
                    t = e._lastMovement
                  }
                  var n = void 0,
                    i = void 0
                  if (t.touches) {
                    var s = t.touches[0]
                    ;(n = s.clientX), (i = s.clientY)
                  } else (n = t.clientX), (i = t.clientY)
                  var o = e.settings.el,
                    l = o.getBoundingClientRect(),
                    a = n - l.left,
                    r = i - l.top,
                    h = a / e.settings.el.clientWidth,
                    d = r / e.settings.el.clientHeight
                  e.boundingBox && e.boundingBox.setPosition(h, d, l),
                    e.settings.zoomPane.setPosition(h, d, l)
                })
            }
          n['default'] = d
        },
        { './BoundingBox': 1, './util/throwIfMissing': 7 },
      ],
      4: [
        function(e, t, n) {
          'use strict'
          function i(e) {
            return e && e.__esModule ? e : { default: e }
          }
          function s(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function')
          }
          Object.defineProperty(n, '__esModule', { value: !0 })
          var o = (function() {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var i = t[n]
                  ;(i.enumerable = i.enumerable || !1),
                    (i.configurable = !0),
                    'value' in i && (i.writable = !0),
                    Object.defineProperty(e, i.key, i)
                }
              }
              return function(t, n, i) {
                return n && e(t.prototype, n), i && e(t, i), t
              }
            })(),
            l = e('./util/throwIfMissing'),
            a = i(l),
            r = e('./util/dom'),
            h = document.createElement('div').style,
            d = 'undefined' != typeof document && ('animation' in h || 'webkitAnimation' in h),
            u = (function() {
              function e() {
                var t = this,
                  n = arguments.length <= 0 || void 0 === arguments[0] ? {} : arguments[0]
                s(this, e),
                  (this._completeShow = function() {
                    t.el.removeEventListener('animationend', t._completeShow, !1),
                      t.el.removeEventListener('webkitAnimationEnd', t._completeShow, !1),
                      (0, r.removeClasses)(t.el, t.openingClasses)
                  }),
                  (this._completeHide = function() {
                    t.el.removeEventListener('animationend', t._completeHide, !1),
                      t.el.removeEventListener('webkitAnimationEnd', t._completeHide, !1),
                      (0, r.removeClasses)(t.el, t.openClasses),
                      (0, r.removeClasses)(t.el, t.closingClasses),
                      (0, r.removeClasses)(t.el, t.inlineClasses),
                      t.el.setAttribute('style', ''),
                      t.el.parentElement === t.settings.container
                        ? t.settings.container.removeChild(t.el)
                        : t.el.parentElement === t.settings.inlineContainer &&
                          t.settings.inlineContainer.removeChild(t.el)
                  }),
                  (this._handleLoad = function() {
                    t.imgEl.removeEventListener('load', t._handleLoad, !1),
                      (0, r.removeClasses)(t.el, t.loadingClasses)
                  }),
                  (this.isShowing = !1)
                var i = n.container,
                  o = void 0 === i ? null : i,
                  l = n.zoomFactor,
                  h = void 0 === l ? (0, a['default'])() : l,
                  d = n.inline,
                  u = void 0 === d ? (0, a['default'])() : d,
                  c = n.namespace,
                  f = void 0 === c ? null : c,
                  m = n.showWhitespaceAtEdges,
                  g = void 0 === m ? (0, a['default'])() : m,
                  v = n.containInline,
                  p = void 0 === v ? (0, a['default'])() : v,
                  y = n.inlineOffsetX,
                  w = void 0 === y ? 0 : y,
                  b = n.inlineOffsetY,
                  _ = void 0 === b ? 0 : b
                ;(this.settings = {
                  container: o,
                  zoomFactor: h,
                  inline: u,
                  namespace: f,
                  showWhitespaceAtEdges: g,
                  containInline: p,
                  inlineOffsetX: w,
                  inlineOffsetY: _,
                }),
                  (this.settings.inlineContainer = document.body),
                  (this.openClasses = this._buildClasses('open')),
                  (this.openingClasses = this._buildClasses('opening')),
                  (this.closingClasses = this._buildClasses('closing')),
                  (this.inlineClasses = this._buildClasses('inline')),
                  (this.loadingClasses = this._buildClasses('loading')),
                  this._buildElement()
              }
              return (
                o(e, [
                  {
                    key: '_buildClasses',
                    value: function(e) {
                      var t = ['drift-' + e],
                        n = this.settings.namespace
                      return n && t.push(n + '-' + e), t
                    },
                  },
                  {
                    key: '_buildElement',
                    value: function() {
                      ;(this.el = document.createElement('div')),
                        (0, r.addClasses)(this.el, this._buildClasses('zoom-pane'))
                      var e = document.createElement('div')
                      ;(0, r.addClasses)(e, this._buildClasses('zoom-pane-loader')),
                        this.el.appendChild(e),
                        (this.imgEl = document.createElement('img')),
                        this.el.appendChild(this.imgEl)
                    },
                  },
                  {
                    key: '_setImageURL',
                    value: function(e) {
                      this.imgEl.setAttribute('src', e)
                    },
                  },
                  {
                    key: '_setImageSize',
                    value: function(e, t) {
                      ;(this.imgEl.style.width = e * this.settings.zoomFactor + 'px'),
                        (this.imgEl.style.height = t * this.settings.zoomFactor + 'px')
                    },
                  },
                  {
                    key: 'setPosition',
                    value: function(e, t, n) {
                      var i = -(this.imgEl.clientWidth * e - this.el.clientWidth / 2),
                        s = -(this.imgEl.clientHeight * t - this.el.clientHeight / 2),
                        o = -(this.imgEl.clientWidth - this.el.clientWidth),
                        l = -(this.imgEl.clientHeight - this.el.clientHeight)
                      if (this.el.parentElement === this.settings.inlineContainer) {
                        var a = window.scrollX,
                          r = window.scrollY,
                          h =
                            n.left + e * n.width - this.el.clientWidth / 2 + this.settings.inlineOffsetX + a,
                          d =
                            n.top + t * n.height - this.el.clientHeight / 2 + this.settings.inlineOffsetY + r
                        if (this.settings.containInline) {
                          this.el.getBoundingClientRect()
                          h < n.left + a
                            ? (h = n.left + a)
                            : h + this.el.clientWidth > n.left + n.width + a &&
                              (h = n.left + n.width - this.el.clientWidth + a),
                            d < n.top + r
                              ? (d = n.top + r)
                              : d + this.el.clientHeight > n.top + n.height + r &&
                                (d = n.top + n.height - this.el.clientHeight + r)
                        }
                        ;(this.el.style.left = h + 'px'), (this.el.style.top = d + 'px')
                      }
                      this.settings.showWhitespaceAtEdges ||
                        (i > 0 ? (i = 0) : i < o && (i = o), s > 0 ? (s = 0) : s < l && (s = l)),
                        (this.imgEl.style.transform = 'translate(' + i + 'px, ' + s + 'px)'),
                        (this.imgEl.style.webkitTransform = 'translate(' + i + 'px, ' + s + 'px)')
                    },
                  },
                  {
                    key: '_removeListenersAndResetClasses',
                    value: function() {
                      this.el.removeEventListener('animationend', this._completeShow, !1),
                        this.el.removeEventListener('animationend', this._completeHide, !1),
                        this.el.removeEventListener('webkitAnimationEnd', this._completeShow, !1),
                        this.el.removeEventListener('webkitAnimationEnd', this._completeHide, !1),
                        (0, r.removeClasses)(this.el, this.openClasses),
                        (0, r.removeClasses)(this.el, this.closingClasses)
                    },
                  },
                  {
                    key: 'show',
                    value: function(e, t, n) {
                      this._removeListenersAndResetClasses(),
                        (this.isShowing = !0),
                        (0, r.addClasses)(this.el, this.openClasses),
                        (0, r.addClasses)(this.el, this.loadingClasses),
                        this.imgEl.addEventListener('load', this._handleLoad, !1),
                        this._setImageURL(e),
                        this._setImageSize(t, n),
                        this._isInline ? this._showInline() : this._showInContainer(),
                        d &&
                          (this.el.addEventListener('animationend', this._completeShow, !1),
                          this.el.addEventListener('webkitAnimationEnd', this._completeShow, !1),
                          (0, r.addClasses)(this.el, this.openingClasses))
                    },
                  },
                  {
                    key: '_showInline',
                    value: function() {
                      this.settings.inlineContainer.appendChild(this.el),
                        (0, r.addClasses)(this.el, this.inlineClasses)
                    },
                  },
                  {
                    key: '_showInContainer',
                    value: function() {
                      this.settings.container.appendChild(this.el)
                    },
                  },
                  {
                    key: 'hide',
                    value: function() {
                      this._removeListenersAndResetClasses(),
                        (this.isShowing = !1),
                        d
                          ? (this.el.addEventListener('animationend', this._completeHide, !1),
                            this.el.addEventListener('webkitAnimationEnd', this._completeHide, !1),
                            (0, r.addClasses)(this.el, this.closingClasses))
                          : ((0, r.removeClasses)(this.el, this.openClasses),
                            (0, r.removeClasses)(this.el, this.inlineClasses))
                    },
                  },
                  {
                    key: '_isInline',
                    get: function() {
                      var e = this.settings.inline
                      return e === !0 || ('number' == typeof e && window.innerWidth <= e)
                    },
                  },
                ]),
                e
              )
            })()
          n['default'] = u
        },
        { './util/dom': 6, './util/throwIfMissing': 7 },
      ],
      5: [
        function(e, t, n) {
          'use strict'
          function i() {
            if (!document.querySelector('.drift-base-styles')) {
              var e = document.createElement('style')
              ;(e.type = 'text/css'),
                e.classList.add('drift-base-styles'),
                e.appendChild(document.createTextNode(s))
              var t = document.head
              t.insertBefore(e, t.firstChild)
            }
          }
          Object.defineProperty(n, '__esModule', { value: !0 }), (n['default'] = i)
          var s =
            '\n@keyframes noop {\n  0% { zoom: 1; }\n}\n\n@-webkit-keyframes noop {\n  0% { zoom: 1; }\n}\n\n.drift-zoom-pane.drift-open {\n  display: block;\n}\n\n.drift-zoom-pane.drift-opening, .drift-zoom-pane.drift-closing {\n  animation: noop 1ms;\n  -webkit-animation: noop 1ms;\n}\n\n.drift-zoom-pane {\n  position: absolute;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n}\n\n.drift-zoom-pane-loader {\n  display: none;\n}\n\n.drift-zoom-pane img {\n  position: absolute;\n  display: block;\n  max-width: none;\n  max-height: none;\n}\n\n.drift-bounding-box {\n  position: absolute;\n  pointer-events: none;\n}\n'
        },
        {},
      ],
      6: [
        function(e, t, n) {
          'use strict'
          function i(e) {
            return a
              ? e instanceof HTMLElement
              : e &&
                  'object' === ('undefined' == typeof e ? 'undefined' : l(e)) &&
                  null !== e &&
                  1 === e.nodeType &&
                  'string' == typeof e.nodeName
          }
          function s(e, t) {
            t.forEach(function(t) {
              e.classList.add(t)
            })
          }
          function o(e, t) {
            t.forEach(function(t) {
              e.classList.remove(t)
            })
          }
          Object.defineProperty(n, '__esModule', { value: !0 })
          var l =
            'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
              ? function(e) {
                  return typeof e
                }
              : function(e) {
                  return e && 'function' == typeof Symbol && e.constructor === Symbol ? 'symbol' : typeof e
                }
          ;(n.isDOMElement = i), (n.addClasses = s), (n.removeClasses = o)
          var a = 'object' === ('undefined' == typeof HTMLElement ? 'undefined' : l(HTMLElement))
        },
        {},
      ],
      7: [
        function(e, t, n) {
          'use strict'
          function i() {
            throw new Error('Missing parameter')
          }
          Object.defineProperty(n, '__esModule', { value: !0 }), (n['default'] = i)
        },
        {},
      ],
    },
    {},
    [2]
  )(2)
})

if (!mobileCheck()) {
  if (pageCheck('plans')) plansDrift()
  if (pageCheck('location')) locationDrift()
}

function plansDrift() {
  const planImg = document.querySelectorAll('.plan-img')

  // planImg.forEach( el =>{
  //   new Drift( el, { inlinePane: true, zoomFactor: 3 });
  // });

  planImg.forEach(el => {
    new Drift(el, {
      inlinePane: true,
      zoomFactor: 3,
    })
  })
}

function locationDrift() {
  const locationImg = document.querySelector('.location-img')

  // new Drift( locationImg, { inlinePane: true, zoomFactor: 3 });
  new Drift(locationImg, {
    inlinePane: true,
    zoomFactor: 3,
  })
}
